import NextErrorComponent from "next/error";
import { Sentry } from "src/sentry";

/**
 * Captures exceptions during modules composition, getInitialProps() or react render.
 * This code was generated by @sentry/wizard.
 */
export default function ErrorPage({ statusCode, isGetInitialPropsSucceeded, err }) {
  const isModuleLevelError = !isGetInitialPropsSucceeded && err;
  if (isModuleLevelError) {
    // https://github.com/vercel/next.js/issues/8592
    Sentry.captureException(err);
  }

  return <NextErrorComponent statusCode={statusCode} />;
}

ErrorPage.getInitialProps = async ({ res, err, asPath }) => {
  const errorInitialProps = await NextErrorComponent.getInitialProps({
    res,
    err,
  });

  // https://github.com/vercel/next.js/issues/8592
  errorInitialProps.isGetInitialPropsSucceeded = true;

  if (err) {
    Sentry.captureException(err);
    await Sentry.flush(2000);
  }
  Sentry.captureException(
    new Error(`_error.js getInitialProps missing data at path: ${asPath}`),
  );
  await Sentry.flush(2000);
  return errorInitialProps;
};
